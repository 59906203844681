import './App.css';

import Main from './_components/Main'
function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
