import axios from 'axios';

// Daten direkt von Webseite laden
export const fetchData = async() => {
  const mappedData = [];
  try {
    const regexpMitarbeiter = /<img[ ]+class=\"wp-tsasp-team-avatar\"[ ]+src=\"(.*?)\"[ ]+alt=\"(.*?)\" \/>/g;
    let res = await axios.get('https://lehmann.ch/team/');
    if(res.data) {
      let srcRegex = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]+class\s*=\s*['"]dipl_team_member_image wp-post-image['"][^>]*>/g;
      let srcMatches = [];
      let srcMatch;
      while ((srcMatch = srcRegex.exec(res.data)) !== null) {
          srcMatches.push(srcMatch[1]);
      }

      let nameRegex = /<h5 class="dipl_team_member_name">([^<]+)<\/h5>/g;
      let nameMatches = [];
      let nameMatch;
      while ((nameMatch = nameRegex.exec(res.data)) !== null) {
          nameMatches.push(nameMatch[1]);
      }

      for (const i in srcMatches) {
        mappedData.push({name: nameMatches[i], url: srcMatches[i]})
      }
      return mappedData;
    }
  } catch (err) {
    console.log(err)
  }

}
