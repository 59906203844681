/*------------------------------------------------------------------------------
  kennich.lehmann.ch

  author:   Marco Lehmann, marco.lehmann@lehmann.ch, A. Lehmann Elektro AG
  date:     15.10.2021

------------------------------------------------------------------------------*/

import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { fetchData } from '../_dataimport';

import { Container, Image, Button, Col, Row, Modal, Spinner } from 'react-bootstrap';

const nrOfOptions = 3;
const variants = ["secondary", "info", "primary"];

ReactGA.initialize('G-NZ6JN24BLM');

const Main = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ options, setOptions ] = useState([]);
  const [ optionsCorrectIndex, setOptionsCorrectIndex ] = useState(null);

  const [ data, setData ] = useState([]);

  const [ score, setScore ] = useState(0);
  const [ showErrorModal, setShowErrorModal ] = useState(false);

  const randomEmployee = () => {
    if(data && data.length > 0) {
      // Array der möglichen Antworten
      const optionsArray = [];
      // Index innerhalb des Arrays, welches die richtige Antwort ist
      const correctIndex = Math.floor(Math.random() * nrOfOptions);

      // neue Zählervariable
      let i = 0;
      // neues Array, um generierte Zufallszahlen absuzpeichern
      const randomNumbers = [];
      // while loop: Solange, bis die Zählervariable unter der nrOfOptions liegt
      while(i < nrOfOptions) {
        // Neue Zufallszahl generieren
        const rand = Math.floor(Math.random() * data.length);
        // Neue Zufallszahl nur dem Array hinzufügen, wenn es diese im Array noch nicht gibt (indexOf === -1)
        if(randomNumbers.indexOf(rand) === -1) {
          randomNumbers.push(rand);
          optionsArray.push({name: data[rand].name, url: data[rand].url});
          i++;
        }
      }
      
      // Variablen in der Developer Console (CTRL + SHIFT + I) ausgeben
      // console.log(randomNumbers);
      // console.log(optionsArray);

      setOptions(optionsArray);
      setOptionsCorrectIndex(correctIndex);
    }
  }

  const buttonClicked = (index) => {
    if(index === optionsCorrectIndex) {
      // richtig
      setScore(score+1);
      randomEmployee();
    } else {
      // falsch
      setShowErrorModal(true);
      setScore(0);
    }
    ReactGA.event({
      category: 'kennich',
      action: 'Score',
      value: score
    });
  }

  const handleModalClose = () => {
    setShowErrorModal(false);
    randomEmployee();
  }

  // wird beim Laden der Seite ausgeführt
  useEffect(() => {
    setLoading(true);
    fetchData().then(d => {setData(d); setLoading(false)});
  }, []);

  // wird ausgeführt, sobal "data" geändert wird
  useEffect(() => {
    randomEmployee();
  }, [data])

  return (
    options && options.length > 0 && optionsCorrectIndex !== null && 
      <Container>
          {loading ? <Spinner /> :
          <>
            <h2>Wer ist es?</h2>
            <Image src={options[optionsCorrectIndex].url} rounded width="267px"/>
            <div className="d-grid gap-2 mt-2">
              {options.map((o, index) => <Button size="lg" key={index} variant={variants[index]} onClick={() => buttonClicked(index)}>{o.name}</Button>)}
            </div>
            <p className="text-end">Score: {score} {score < 3 ? "🥱" : score < 6 ? "🤨" : score < 9 ? "😊" : score < 12 ? "😁" : score < 15 ? "🤩" : "🚀"}</p>
            <Modal show={showErrorModal} onClick={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Falsch!</Modal.Title>
              </Modal.Header>
              <Modal.Body>Das war <b>{options[optionsCorrectIndex]?.name}</b></Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleModalClose}>
                  weiter
                </Button>
              </Modal.Footer>
            </Modal>
          </>}
      </Container>
  )
}

export default Main;